/*
Custom Javascript Functions by Immersus Media
*/

// show the top bar right menu slightly delayed
jQuery(document).ready(function() {
     jQuery('.top-bar-right').show();
});

// search icon and input field toggle
jQuery("img.search-icon").click( function() {
    jQuery(".search-form").toggleClass("search-open");
    jQuery(".search-field").focus();
} );

jQuery(".dispensing-tab").click( function() {
	jQuery(".dispensing-options").fadeIn();
	jQuery(".product-options").hide();
	jQuery("ul.products:nth-of-type(2)").hide();

})

jQuery(".product-tab").click( function() {
	jQuery(".dispensing-options").show();
	jQuery(".dispensing-options").hide();
	jQuery(".product-options").fadeIn();
	jQuery("ul.products:nth-of-type(2)").fadeIn();
})

jQuery('.woocommerce .kt-tabs-title-list').on('click','.kt-title-item', function(){
	jQuery(this).addClass('kt-tab-title-active').siblings().removeClass('kt-tab-title-active');
});
